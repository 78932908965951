import React, { useState, useContext } from 'react';
import axiosClient from '../components/AxiosClient';
import { useNavigate } from 'react-router-dom';
import vendorContext from '../context/vendor/vendorContext';

const VendorLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();
  const { setExpiredToken } = useContext(vendorContext);

  const handleSendOtp = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!phoneNumber) {
        console.error('Please enter your phone number.');
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('phone_number', phoneNumber);
      formData.append('role', 'vendor');

      const response = await axiosClient.post('/auth/send_otp', formData);
      if (response.status === 200) {
        console.log('OTP sent:', response.data);
        setIsOtpSent(true);
        alert("OTP sent successfully.");
      } else {
        console.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
    setIsLoading(false);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!otp || !phoneNumber) {
        console.error('Please fill in both OTP and phone number.');
        setIsLoading(false);
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('mobile', phoneNumber);
      formDataToSend.append('otp', otp);

      const response = await axiosClient.post('/auth/vendor_login', formDataToSend);
      console.log('Vendor Login successful:', response.data);

      // Store token in localStorage
      localStorage.setItem('vendorToken', response.data.data[0].access_token);

      // Update expiredToken state
      // setExpiredToken(false);
      console.log("Login successful, token stored and expiredToken set to false");

      // Add a 1-second delay before navigating to the dashboard
      setTimeout(() => {
        navigate('/vendordashboard');
      }, 1000); // 1 second = 1000 milliseconds
    } catch (error) {
      console.error('Error logging in:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-100 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Vendor Login</h2>
        {!isOtpSent ? (
          <form onSubmit={handleSendOtp}>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block mb-2">Phone Number:</label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter your phone number"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              disabled={isLoading}
            >
              {isLoading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="otp" className="block mb-2">Enter OTP:</label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter OTP"
              />
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default VendorLogin;
