import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VendorLogin from './Pages/VendorLogin';
import VendorDashboard from './Pages/VendorDashboard';
import VendorCoupons from './Pages/VendorCoupons';
import VendorCouponExpiry from './Pages/VendorCouponExpiry';
import VendorProducts from './Pages/VendorProducts';
import VendorAddProd from './Pages/VendorAddProd';
import Createsmartdeals from './Pages/VendorCreateSmartDeals';
import SmartDeals from './Pages/VendorSmartDeals';
import VendorState from './context/vendor/VendorState';
import TokenExpiryChecker from "./components/TokenExpiry"
function App() {
  return (
    <Router>
      <VendorState>
      <TokenExpiryChecker/>
      <Routes>
        <Route exact path="/" element={<VendorLogin />} />
        <Route exact path='/vendordashboard' element={<VendorDashboard/>}/>
        <Route path="/vendordashboard/*" element={<VendorProtectedRoutes />} />
      </Routes>
      </VendorState>
    </Router>
  );
}

function VendorProtectedRoutes() {
  return (
    <VendorDashboard>
      <Route path="/coupons" element={<VendorCoupons />} />
      <Route path="/couponexpiry" element={<VendorCouponExpiry/>} />
      <Route path="/products" element={<VendorProducts/>} />
      <Route path="/addproduct" element={<VendorAddProd/>} />
      <Route path='/createsmartdeals' element={<Createsmartdeals/>}/>
      <Route path="/smartdeals" element={<SmartDeals/>}></Route>
    </VendorDashboard>
  );
}

export default App