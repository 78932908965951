import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';

const VendorAddProd = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    
    try {
      // Retrieve vendor token from local storage
      const vendorToken = localStorage.getItem('vendorToken');
      if (!vendorToken) {
        toast.error('Vendor token not found in local storage');
        return;
      }

      // Check if a file is selected
      if (!selectedFile) {
        toast.error('No file selected');
        return;
      }

      // Create FormData to send the file
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Send the file to the upload API
      const response = await axiosClient.post('/products/upload_products', formData, {
        headers: {
          'Authorization': `Bearer ${vendorToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      // Show a success toast
      if (response.status === 200) {
        toast.success('File uploaded successfully');
      } else {
        toast.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with status:', error.response.status);
        console.error('Response data:', error.response.data);
        
        toast.error(`Failed to upload file: ${error.response.data.message || 'Internal Server Error'}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        toast.error('No response from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up request:', error.message);
        toast.error('Error setting up request');
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <form
        className="bg-white p-6 rounded shadow-md w-full max-w-md"
        onSubmit={handleUpload}
      >
        <h2 className="text-2xl font-bold mb-4">Add Product</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Upload CSV/Excel File
          </label>
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Add Product
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default VendorAddProd;
