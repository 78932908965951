import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../components/AxiosClient';

const VendorProducts = () => {
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [saltExpiryDate, setSaltExpiryDate] = useState("");
  const [minPrice, setMinPrice] = useState(0.0);
  const [maxPrice, setMaxPrice] = useState(0.0);
  const [minDiscount, setMinDiscount] = useState(0.0);
  const [maxDiscount, setMaxDiscount] = useState(0.0);

  const [showMRPModal, setShowMRPModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [newMRP, setNewMRP] = useState('');
  const [newDiscount, setNewDiscount] = useState('');

  const editMRP = (productId) => {
    setCurrentProduct(productId);
    setShowMRPModal(true);
  };

  const editDiscount = (productId) => {
    setCurrentProduct(productId);
    setShowDiscountModal(true);
  };

  const handleSubmitMRP = useCallback(async () => {
    if (!newMRP || isNaN(newMRP)) {
        alert('Please enter a valid MRP.');
        return;
    }

    try {
        const vendorToken = localStorage.getItem('vendorToken');
        if (vendorToken) {
            const headers = {
                'Authorization': `Bearer ${vendorToken}`,
                'Content-Type': 'multipart/form-data', // Set content type for FormData
            };

            const formData = new FormData();
            formData.append('new_mrp', parseFloat(newMRP));

            await axiosClient.post(`/products/vendor_edit_mrp/${currentProduct}`, formData, { headers });

            setShowMRPModal(false);
            setNewMRP('');
            fetchProducts();
        }
    } catch (error) {
        console.error('Error updating MRP:', error);
    }
}, [newMRP, currentProduct]);
const handleSubmitDiscount = useCallback(async () => {
  if (!newDiscount || isNaN(newDiscount)) {
      alert('Please enter a valid discount.');
      return;
  }

  try {
      const vendorToken = localStorage.getItem('vendorToken');
      if (vendorToken) {
          const headers = {
              'Authorization': `Bearer ${vendorToken}`,
              'Content-Type': 'multipart/form-data', // Set content type for FormData
          };

          const formData = new FormData();
          formData.append('discount', parseFloat(newDiscount));

          await axiosClient.post(`/products/vendor_discount/${currentProduct}`, formData, { headers });

          setShowDiscountModal(false);
          setNewDiscount('');
          fetchProducts();
      }
  } catch (error) {
      console.error('Error updating discount:', error);
  }
}, [newDiscount, currentProduct]);

  const fetchProducts = async () => {
    try {
      const vendorToken = localStorage.getItem('vendorToken');
      if (vendorToken) {
        let formData = null;
        const headers = {
          'Authorization': `Bearer ${vendorToken}`
        };

        if (name || saltExpiryDate || minPrice || maxPrice || minDiscount || maxDiscount) {
          formData = new FormData();
          if (name) formData.append('name', name);
          if (saltExpiryDate) formData.append('salt_expiry_date', saltExpiryDate);
          if (minPrice) formData.append('min_price', parseFloat(minPrice));
          if (maxPrice) formData.append('max_price', parseFloat(maxPrice));
          if (minDiscount) formData.append('min_discount', parseFloat(minDiscount));
          if (maxDiscount) formData.append('max_discount', parseFloat(maxDiscount));
          headers['Content-Type'] = 'multipart/form-data';
        }

        const response = await axiosClient.post('/products/get_vendor_products', formData, { headers });
        setProducts(response.data || []);
      } else {
        console.error('Vendor token not found in local storage');
      }
    } catch (error) {
      console.error('Error fetching vendor products:', error);
    }
  };
  useEffect(() => {
    // Fetch products data from the API
    fetchProducts();
  }, [name, saltExpiryDate, minPrice, maxPrice, minDiscount, maxDiscount]);

  return (
    <div>
      <h2>Vendor Products</h2>
      <div>
        Sorting features : <br />
        <div>
          <label>
            Name:
            <input
              type='text'
              className=' '
              onChange={(e) => setName(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Salt Expiry Date:
            <input
              type='date'
              className=' '
              onChange={(e) => setSaltExpiryDate(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Min Price:
            <input
              type='number'
              className=' '
              onChange={(e) => setMinPrice(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Max Price:
            <input
              type='number'
              className=' '
              onChange={(e) => setMaxPrice(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Min Discount:
            <input
              type='number'
              className=' '
              onChange={(e) => setMinDiscount(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Max Discount:
            <input
              type='number'
              className=' '
              onChange={(e) => setMaxDiscount(e.target.value)}
            />
          </label>
        </div>
      </div>
      <div className="grid grid-cols-1">
        <div className='flex'>
          <div className="p-4">Image</div>
          <div className="p-4">Name</div>
          <div className="p-4">MRP</div>
        </div>
        {products.map(product => (
          <div key={product.object_id} className='flex my-2'>
            <div className='flex'>
              <div className="p-4">
                <img src={product.image ? `data:image/jpeg;base64,${product.image.image_base64}` : 'placeholder.jpg'} alt="Product" className="w-full" />
              </div>
              <div className="p-4">{product.name}</div>
              <div className="p-4">MRP: {product.Mrp}</div>
              <div className="p-4">Discount: {product.discount}</div>
            </div>
            <div>
              <button className='bg-orange-300 py-4 px-2 mx-1 rounded-md' onClick={() => editMRP(product.object_id)}>Edit MRP</button>
              <button className='bg-orange-300 py-4 px-2 mx-1 rounded-md' onClick={() => editDiscount(product.object_id)}>Edit Discount</button>
            </div>
          </div>
        ))}
      </div>

      {/* MRP Modal */}
      {showMRPModal && (
        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-4 rounded'>
            <h3>Edit MRP</h3>
            <input
              type='number'
              value={newMRP}
              onChange={(e) => setNewMRP(e.target.value)}
              placeholder='Enter new MRP'
              className='border p-2 rounded'
            />
            <div className='mt-2'>
              <button className='bg-blue-500 text-white py-2 px-4 rounded' onClick={handleSubmitMRP}>Submit</button>
              <button className='bg-red-500 text-white py-2 px-4 rounded ml-2' onClick={() => setShowMRPModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Discount Modal */}
      {showDiscountModal && (
        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-4 rounded'>
            <h3>Edit Discount</h3>
            <input
              type='number'
              value={newDiscount}
              onChange={(e) => setNewDiscount(e.target.value)}
              placeholder='Enter new discount'
              className='border p-2 rounded'
            />
            <div className='mt-2'>
              <button className='bg-blue-500 text-white py-2 px-4 rounded' onClick={handleSubmitDiscount}>Submit</button>
              <button className='bg-red-500 text-white py-2 px-4 rounded ml-2' onClick={() => setShowDiscountModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorProducts;
