import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from '../components/AxiosClient';

const VendorCouponExpiry = () => {
  const [expiryData, setExpiryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState('');
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setDays(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const vendorToken = localStorage.getItem('vendorToken');
      const formData = new FormData();
      formData.append('days', days);

      const response = await axiosClient.post(
        '/coupons/get_users_coupon_expiry',
        formData,
        {
          headers: {
            Authorization: `Bearer ${vendorToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
console.log('fetched success')
      setExpiryData(response.data.data);
    } catch (error) {
      setError('Error fetching expiry data');
      console.error('Error fetching expiry data:', error);
    }

    setIsLoading(false);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold my-8">Users Coupon Expiry</h1>
      <form onSubmit={handleSubmit}>
        <div className="flex mb-4">
          <label htmlFor="days" className="mr-4">
            Number of days:
          </label>
          <input
            type="number"
            id="days"
            name="days"
            value={days}
            onChange={handleChange}
            className="border border-gray-300 rounded-md px-3 py-1"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-blue-600"
        >
          Fetch Expiry Data
        </button>
      </form>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {expiryData && expiryData.length > 0 && (
  <div>
    <h2 className="text-xl font-semibold mt-4">Expiry Data</h2>
    <ul>
      {expiryData.map((expiry, index) => (
        <li key={index}>
          <p>User ID: {expiry.user_id}</p>
          <p>User Name: {expiry.user_name}</p>
          <p>Coupon Expiry: {expiry.coupon_expiry}</p>
        </li>
      ))}
    </ul>
  </div>
)}
    </div>
  );
};

export default VendorCouponExpiry;
