import React,{useState,useEffect} from "react";
import axiosClient from '../components/AxiosClient';

const SmartDeals=()=>{
    const [deals, setDeals] = useState([]);
    useEffect(() => {
        // Fetch products data from the API
        const fetchProducts = async () => {
          try {
            // Retrieve vendor token from local storage
            const vendorToken = localStorage.getItem('vendorToken');
            
            // Check if vendor token exists
            if (vendorToken) {
              const response = await axiosClient.post('/smartdeals/get_deals',null, {
                headers: {
                  'Authorization': `Bearer ${vendorToken}`
                }
              });
              console.log('vendor deals fetched successfully')
              setDeals(response.data.deals || []); // Set products to response.data or an empty array if response.data is falsy
            } else {
              console.error('Vendor token not found in local storage');
            }
          } catch (error) {
            console.error('Error fetching vendor products:', error);
          }
        };
    
        fetchProducts();
      }, []);
    return(
        <>
        <div>Your Deals</div>
        {deals.map(deal => (
          <div key={deal.deal_id} className="bg-slate-200">
            <div className="p-4">Product Id: {deal.product_id}</div>
            <div className="p-4">Discout : {deal.discount_percentage}</div>
            <div className="p-4">Start Date : {Date(deal.start_date)}</div>
            <div className="p-4">End Date : {Date(deal.end_date)}</div>
            <div className="p-4">Status: {deal.status}</div>
            <div className="p-4">Created at : {Date(deal.created_at)}</div>
          </div>
        ))}
        </>
    )
}
export default SmartDeals